<template>
  <div class="bindInvitationCode" @click.stop>
    <Header title="绑定邀请码" />
    <div class="main">
      <van-field class="invitationCode" v-model="invitationCode" label="邀请码">
        <template #button>
          <div v-if="invitationCode" @click="clearInput">
            <svg-icon class="closeIcon" iconClass="closeIcon" />
          </div>
        </template>
      </van-field>
      <div class="tip">
        <span>注：</span>
        只能绑定一次，且绑定后不允许修改。
      </div>
      <div class="bindBtn" @click="confirmBind">立即绑定</div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header';
import { Field, Toast } from 'vant';
import { inviteBind } from '@/api/user';

export default {
  name: 'ActRecovery',
  data() {
    return {
      invitationCode: '',
    };
  },
  components: {
    Header,
    [Field.name]: Field,
  },
  methods: {
    clearInput() {
      this.invitationCode = '';
    },
    async confirmBind() {
      if (!this.invitationCode) {
        Toast('请输入邀请码');
        return;
      }
      let req = {
        promotionCode: this.invitationCode,
      };
      try {
        this.$store.commit('app/SET_LOADING', true);
        let ret = await this.$Api(inviteBind, req);
        if (ret && ret.code === 200) {
          this.$store.dispatch('user/getUserInfo');
          Toast(ret.tip || '绑定成功');
          this.$router.go(-1);
        } else if (ret.tip) {
          Toast(ret.tip || '绑定失败');
        }
        this.$store.commit('app/SET_LOADING', false);
      } catch (error) {
        this.$store.commit('app/SET_LOADING', false);
        Toast('绑定失败');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.bindInvitationCode {
  height: 100%;
  .main {
    padding: 0 20px;
    box-sizing: border-box;
    height: calc(100% - 44px);
    overflow-y: auto;
    .invitationCode {
      // width: 335px;
      height: 58px;
      // margin: 0 auto;
      padding: 15.5px 21px 15.5px 26px;
      box-sizing: border-box;
      background: rgba(248, 248, 249, 0.7);
      border-radius: 10px;
      margin-top: 17px;
      /deep/ .van-cell__title {
        width: 56px;
        border-right: 1px solid #e1e1ea;
        font-size: 14px;
        color: #666666;
      }
    }
    .tip {
      margin-top: 12px;
      color: #919191;
      font-size: 11px;
      display: flex;
    }
    .bindBtn {
      width: 258px;
      height: 38px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 76px auto;
      color: #fff;
      background-image: linear-gradient(to right, #f74a82, #fca984);
    }
  }
}
</style>
